import React from "react";
import "../styles/animate.min.css";
import "../styles/Home.css";

export function Home() {
    return(
        <section class="screen">
        <div class="container">
            <div class="headerTexts">
                <h1 class="animate__animated animate__bounceInLeft">Plataforma SAC</h1>
                <h2 class="animate__animated animate__bounceInRight">Redepharma</h2>
            </div>
            <div class="bodyTexts">
                <p class="animate__animated animate__zoomIn animate__delay">Seja bem-vindo(a) à plataforma, eu sou a Rê e vou te acompanhar durante o processo. Este é um canal exclusivo da <strong>Redepharma</strong> para comunicação segura de condutas consideradas antiéticas ou que violem os princípios éticos e padrões de conduta da companhia e/ou a legislação vigente.<br/><br/>Para começar, preciso saber, você deseja enviar um novo protocolo, ou acompanhar um protocolo já existente?</p>
            </div>
            <div class="footerBottom">
                <a href="./novo">
                    <div class="btn-redepharma animate__animated animate__backInUp">
                        <span>Novo</span>
                    </div>
                </a>
                <a href="./acompanhar">
                    <div class="btn-redepharma animate__animated animate__backInUp">
                        <span>Acompanhar</span>
                    </div>
                </a>
            </div>
        </div>
        <div class="imageRe">
            <img class="animate__animated animate__fadeInUpBig" src="/re-welcome.png" alt="Rê" />
        </div>
    </section>
    );
}





// import Axios from "axios";
// const handleClickButton = () =>{
//     Axios.post("http://localhost:3001/register", {
//     name: values.name,
//     email: values.email,
//     category: values.category,
//     local: values.local,
//     recType: values.recType,
//     texto: values.texto,
//     rgCpf: values.rgCpf,
//     celular: values.celular,
//   }).then((response)=>{
//     console.log(response);
//   }) 
// }; 