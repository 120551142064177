import React, { useState } from 'react';
import "../styles/animate.min.css";
import "../styles/Novo.css";
import Axios from 'axios';
import InputMask from 'react-input-mask';

export function Novo() {
    // Boolean's
    const [isAnonymous, setIsAnonymous] = useState(false);
    const [allowPermissions, setAllowPermissions] = useState(false);
    
    const [firstPage, setFirstPage] = useState(true);
    const [nextPage1, setNextPage1] = useState(false);
    const [nextPage2, setNextPage2] = useState(false);
    const [nextPage3, setNextPage3] = useState(false);

    // String's
    const [ticketUserName, setTicketUserName] = useState("");
    const [ticketUserEmail, setTicketUserEmail] = useState("");
    const [ticketUserCPF, setTicketUserCPF] = useState("");
    const [ticketUserTelefone, setTicketUserTelefone] = useState("");
    
    const [ticketType, setTicketType] = useState("Elogio");
    
    const [ticketText, setTicketText] = useState("");
    
    const [ticketId, setTicketId] = useState("");

    // Function's
    function checkAnonymous() {
        setIsAnonymous(!isAnonymous);
        setTicketUserCPF("");
        setTicketUserEmail("");
        setTicketUserName("");
        setTicketUserTelefone("");
    };
    function checkAllowPermissions() {
        setAllowPermissions(!allowPermissions);
    };
    async function handleSubmitTicket() {
        if(ticketText !== ''){
            await Axios.post("https://sac-api.onrender.com/register", {
                name: ticketUserName,
                email: ticketUserEmail,
                cpf: ticketUserCPF,
                telefone: ticketUserTelefone,
                tipo: ticketType,
                texto: ticketText
            }).then((result)=>{setTicketId(result.data.id)});
            console.log("Click");
            funcNextPage3();
        }else{alert("Digite o conteúdo do texto.")}
    }
    function funcFirstPage() {
        setFirstPage(true);
        setNextPage1(false);
        setNextPage2(false);
        setNextPage3(false);        
    };
    function funcNextPage1() {
        if(isAnonymous) {
            setFirstPage(false);
            setNextPage1(true);
            setNextPage2(false);
            setNextPage3(false); 
        }else{
            if(ticketUserName && ticketUserEmail && ticketUserCPF && ticketUserTelefone !== '' && ticketUserCPF.length === 14 && ticketUserTelefone.length === 15){
                if(allowPermissions){
                    setFirstPage(false);
                    setNextPage1(true);
                    setNextPage2(false);
                    setNextPage3(false); 
                }else{
                    alert("Para prosseguir com seus dados, você precisa concordar com os termos de identificação.")
                }
            }else{
                alert("Preencha todos os campos corretamente.");
                console.log(ticketUserCPF.length);
            }      
        }
    };
    function funcNextPage2() {
        setFirstPage(false);
        setNextPage1(false);
        setNextPage2(true);
        setNextPage3(false);        
    };
    function funcNextPage3() {
        setFirstPage(false);
        setNextPage1(false);
        setNextPage2(false);
        setNextPage3(true);        
    };

    return(
        <>
        {firstPage ? (
        <section className="new--screen">
        <div className="new--container">
            <div className="new--headerTexts">
                <h1 className="animate__animated animate__bounceInLeft">Novo</h1>
                <h2 className="animate__animated animate__bounceInRight">Ticket</h2>
            </div>
            <div className="new--bodyTexts">
                <p className="animate__animated animate__zoomIn animate__delay">Ótimo, para começar, preciso saber se você gostaria de se identificar.</p>
                <p className="animate__animated animate__zoomIn animate__delay">Caso queira marque a opção "Concordo com os termos de identificação"</p>
            </div>
            <div className="new--footerForm">
                <div className="new--footerInput animate__animated animate__backInUp">
                    <input
                        value={isAnonymous}
                        onChange={checkAnonymous}
                        type="checkbox"
                        id="checa_identificacao2"
                        />
                    <label htmlFor="checa_identificacao2" id="label_identifica">Desejo me manter anônimo</label>
                    <input
                        disabled={isAnonymous}
                        value={ticketUserName}
                        onChange={e => setTicketUserName(e.target.value)}
                        className="redepharma-input"
                        type="text"
                        placeholder="Nome completo"
                        />
                    <input
                        disabled={isAnonymous}
                        value={ticketUserEmail}
                        onChange={e => setTicketUserEmail(e.target.value)}
                        className="redepharma-input"
                        type="email"
                        placeholder="Email"
                        />
                    <div className="new--dados-numericos">
                        <InputMask
                            maskChar=""
                            mask="999.999.999-99"
                            disabled={isAnonymous}
                            value={ticketUserCPF}
                            onChange={e => setTicketUserCPF(e.target.value)}
                            className="redepharma-input"
                            type="text"
                            placeholder="CPF"
                            />
                        <InputMask
                            maskChar=""
                            mask="(99)9.9999-9999"
                            disabled={isAnonymous}
                            value={ticketUserTelefone}
                            onChange={e => setTicketUserTelefone(e.target.value)}
                            className="redepharma-input"
                            type="text"
                            placeholder="Telefone"
                            />
                    </div>
                    <input
                        disabled={isAnonymous}
                        value={allowPermissions}
                        onChange={checkAllowPermissions}
                        type="checkbox"
                        id="checa_identificacao"
                        />
                    <label htmlFor="checa_identificacao" id="label_identifica">Concordo com os <a href="/termo"><strong>termos de identificação</strong></a></label>
                </div>
                <div className="new--footerBottom">
                    <div onClick={funcNextPage1} className="btn-redepharma animate__animated animate__backInUp">
                        <span>Próximo</span>
                    </div>
                    <a href="./">
                        <div className="btn-redepharma animate__animated animate__backInUp">
                            <span>Cancelar</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div className="new--imageRe">
            <img className="animate__animated animate__fadeInUpBig" src="/re-welcome.png" alt="Rê"/>
        </div>
    </section>) : ''}

    {nextPage1 ? (
    <section className="new--screen">
        <div className="new--container">
            <div className="new--headerTexts">
                <h1 className="animate__animated animate__bounceInLeft">Novo</h1>
                <h2 className="animate__animated animate__bounceInRight">Ticket</h2>
            </div>
            <div className="new--bodyTexts">
                <p className="animate__animated animate__zoomIn animate__delay">Perfeito{isAnonymous ? '' : ', '+ticketUserName}. Agora precisamos saber o motivo de sua visita para dar continuidade ao atendimento.</p>
            </div>
            <div className="new--footerForm">
                <div className="new--footerInput animate__animated animate__backInUp">
                    <select value={ticketType} onChange={e => setTicketType(e.target.value)} className="redepharma-select">
                        <option value="Elogio">Elogio</option>
                        <option value="Reclamação">Reclamação</option>
                        <option value="Sugestão">Sugestão</option>
                    </select>
                </div>
                <div className="new--footerBottom">
                    <div onClick={funcNextPage2} className="btn-redepharma animate__animated animate__backInUp">
                        <span>Próximo</span>
                    </div>
                    <div onClick={funcFirstPage} className="btn-redepharma animate__animated animate__backInUp">
                        <span>Voltar</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="new--imageRe">
            <img className="animate__animated animate__fadeInUpBig" src="/re-welcome.png" alt="Rê"/>
        </div>
    </section>) : ''}

    {nextPage2 ? (
        <section className="new--screen">
        <div className="new--container">
            <div className="new--headerTexts">
                <h1 className="animate__animated animate__bounceInLeft">Novo</h1>
                <h2 className="animate__animated animate__bounceInRight">Ticket</h2>
            </div>
            <div className="new--bodyTexts">
                <p className="animate__animated animate__zoomIn animate__delay">Por último, e não menos importante, escreva o conteúdo do ticket.</p>
            </div>
            <div className="new--footerForm">
                <div className="footerInput animate__animated animate__backInUp">
                    <textarea value={ticketText} onChange={e => setTicketText(e.target.value)} placeholder="Escreva aqui..." className="redepharma-input redepharma-textarea"></textarea>
                </div>
                <div className="new--footerBottom">
                    <div onClick={handleSubmitTicket} className="btn-redepharma animate__animated animate__backInUp">
                        <span>Próximo</span>
                    </div>
                    <div onClick={funcNextPage1} className="btn-redepharma animate__animated animate__backInUp">
                        <span>Cancelar</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="new--imageRe">
            <img className="animate__animated animate__fadeInUpBig" src="/re-welcome.png" alt="Rê"/>
        </div>
    </section>) : ''}

    {nextPage3 ? (
        <section className="new--screen">
        <div className="new--container">
            <div className="new--headerTexts">
                <h1 className="animate__animated animate__bounceInLeft">Novo</h1>
                <h2 className="animate__animated animate__bounceInRight">Ticket</h2>
            </div>
            <div className="new--bodyTexts">
                <p className="animate__animated animate__zoomIn animate__delay">Seu ticket foi enviado com sucesso para os nossos servidores. Você pode acompanhar o processo a partir do número de protocolo.
                    <br/> <br/>
                    <b>
                        NÚMERO DO TICKET:
                    </b>
                    <br/>
                    <strong>
                        #{ticketId}
                    </strong>
                 </p>
            </div>
            <div className="new--footerForm">
                <div className="new--footerBottom">
                <a href="./acompanhar">
                    <div className="btn-redepharma animate__animated animate__backInUp">
                        <span>Acompanhar</span>
                    </div>
                </a>
                <a href="./">
                    <div className="btn-redepharma animate__animated animate__backInUp">
                        <span>Início</span>
                    </div>
                </a>
                </div>
            </div>
        </div>
        <div className="new--imageRe">
            <img className="animate__animated animate__fadeInUpBig" src="/re-welcome.png" alt="Rê"/>
        </div>
    </section>) : ''}
    </>
      );
}
