import React from "react";
import "../styles/animate.min.css";
import "../styles/Acompanhar.css";

export function Termo() {
    return(
        <section className="acp--screen">
        <div className="acp--container">
            <div className="acp--headerTexts">
                <h1 className="animate__animated animate__bounceInLeft">Termo de Confiabilidade</h1>
                <h2 className="animate__animated animate__bounceInRight">Redepharma</h2>
            </div>
            <div className="acp--bodyTexts">
                <p className="animate__animated animate__zoomIn animate__delay termo p-termo">Os relatos registrados por esta plataforma serão recebidos e assegurados do sigilo absoluto com o valor primordial da ética e integridade, e o tratamento adequado de cada situação pelo Comitê de Ética da empresa, composta pela alta administração, sem conflitos de interesse.<br/><br/>Todos os usuários que utilizarem desse meio estão assegurados que nenhuma informação pessoal, ou do relato será divulgado, compartilhado, reproduzido, ou dado conhecimento em qualquer meio de comunicação ou a terceiros, garantindo a segurança do nosso banco de dados, restringindo o acesso apenas para os responsáveis pelo tratamento dos relatos, seguindo a legislação da Lei Geral de Proteção de Dados 13.709/2018.</p>
            </div>
            <div className="acp--footerBottom">
                <a href="./novo">
                    <div className="btn-redepharma animate__animated animate__backInUp">
                        <span>Voltar</span>
                    </div>
                </a>
            </div>
        </div>
        <div className="acp--imageRe">
            <img className="animate__animated animate__fadeInUpBig" src="/re-welcome.png" alt="Rê" />
        </div>
    </section>
    );
}





// import Axios from "axios";
// const handleClickButton = () =>{
//     Axios.post("http://localhost:3001/register", {
//     name: values.name,
//     email: values.email,
//     category: values.category,
//     local: values.local,
//     recType: values.recType,
//     texto: values.texto,
//     rgCpf: values.rgCpf,
//     celular: values.celular,
//   }).then((response)=>{
//     console.log(response);
//   }) 
// }; 