import React, { useState } from "react";
import "../styles/animate.min.css";
import "../styles/Acompanhar.css";
import Modal from 'react-modal';
import Axios from 'axios';


const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      height: '85%',
      width: '84%',
      borderRadius: '40px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };


  Modal.setAppElement('#root');

export function Acompanhar() {
    
    const [modalIsOpen, setIsOpen] = useState(false);
    const [idProtocolo, setIdProtocolo] = useState("");
    const [ticketStatus, setTicketStatus] = useState(null);
    const [ticketResposta, setTicketResposta] = useState(null);
    const [ticketNome, setTicketNome] = useState(null);
    
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    async function getInfoTicket(value) {
        await Axios.get(`https://sac-api.onrender.com/db/sac/${value}`)
        .then( (result)=>{ 
            setTicketStatus(result.data.result[0].status);
            setTicketResposta(result.data.result[0].resposta);
            setTicketNome(result.data.result[0].nome);
        });
        
    }
    
    
    return(

        <section className="acp--screen">
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <section>
                    <div>
                        <div>
                            <h2><strong>Status</strong></h2>
                            {ticketStatus === "Pendente" ? (<h2 className='cor-vermelho'>Pendente</h2>) : ""}
                            {ticketStatus === "Resolvido" ? (<h2 className='cor-verde'>Resolvido</h2>) : ""}
                        </div>
                        <div>
                            <h2><strong>Nome</strong></h2>
                            <div>{ticketNome}</div>
                        </div>
                    </div>
                    <div>
                        <h2><strong>Resposta:</strong></h2>
                        <p>{ticketResposta}</p>
                    </div>
                    <div>
                    <div
                        onClick={()=> closeModal()}
                        className="btn-redepharma">
                        <span>Fechar</span>
                    </div>
                    </div>
                </section>
            </Modal>
        <div className="acp--container">
            <div className="acp--headerTexts">
                <h1 className="animate__animated animate__bounceInLeft">Ouvidoria/SAC</h1>
                <h2 className="animate__animated animate__bounceInRight">Redepharma</h2>
            </div>
            <div className="acp--bodyTexts">
                <p className="animate__animated animate__zoomIn animate__delay">Para acompanhar é simples, basta me informar o número do seu protocolo no campo a baixo.</p>
            </div>
            <div className="acp--footerForm">
                <div className="acp--footerInput animate__animated animate__backInUp">
                    <input value={idProtocolo} onChange={e => setIdProtocolo(e.target.value)} className="redepharma-input" type="number" placeholder="Digite aqui o número do ticket" />
                </div>
                <div className="acp--footerBottom">
                    <a href="./">
                        <div className="btn-redepharma animate__animated animate__backInUp">
                            <span>Voltar</span>
                        </div>
                    </a>
                    <div
                        onClick={()=> {
                            getInfoTicket(idProtocolo);
                            openModal();
                        }}
                        className="btn-redepharma animate__animated animate__backInUp">
                        <span>Acompanhar</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="acp--imageRe">
            <img className="animate__animated animate__fadeInUpBig" src="/re-welcome.png" alt="Re"/>
        </div>
    </section>
    );
}
