import { Route, Routes } from 'react-router-dom';
import { Home } from "./pages/Home";
import { Acompanhar } from "./pages/Acompanhar";
import { Novo } from "./pages/Novo";
import { Termo } from "./pages/Termo";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/novo" element={<Novo />} />
      <Route path="/acompanhar" element={<Acompanhar />} />
      <Route path="/termo" element={<Termo />} />
    </Routes>
  );
}
